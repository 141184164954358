<script setup>
import { useStore } from "vuex";

const props = defineProps({
  media: Object,
  playsinline: Boolean,
  controls: Boolean,
});

const emit = defineEmits(["loaded"]);

const video = ref(null);

const target = ref(null);

const store = useStore();

const videoProps = computed(() => ({
  ...props.media.attributes,
  controls: props.controls,
}));

const isPlaying = ref(
  props.media.attributes.muted && props.media.attributes.autoplay,
);
const progress = ref(0);

const { pause: pauseProgress, resume: resumeProgress } = useRafFn(() => {
  if (!video.value) return;
  progress.value = video.value.currentTime / video.value.duration;
});

const handlePlay = () => {
  if (props.media.attributes.loop && props.playsinline) return;

  if (props.playsinline) {
    isPlaying.value = !isPlaying.value;
  } else {
    // Display video in overlay....

    store.dispatch("modals/open", {
      id: "ModalVideo",
      data: {
        data: props.media,
        playsinline: true,
      },
    });
  }
};

const handleSeek = (e) => {
  video.value.currentTime = e.target.value * video.value.duration;
};

const handleVolume = (e) => {
  video.value.volume = e.target.value;
};

watch(isPlaying, () => {
  if (!video.value) return;

  if (isPlaying.value) {
    video.value.play();
    resumeProgress();
  } else {
    video.value.pause();
    pauseProgress();
  }
});

const zeroPad = (x) => x.toString().padStart(2, "0");

const parseTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.round(time - minutes * 60);

  return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
};

onMounted(() => {
  emit("loaded");
});
</script>

<template>
  <div class="video h-full w-full" :class="[media.extension]">
    <div class="ui" ref="target" v-if="!playsinline && !media.attributes.loop">
      <button class="play-btn" @click="handlePlay" v-if="!isPlaying">
        <div class="icon">
          <ui-icons-play />
        </div>
      </button>

      <!-- <div class="timeline" v-if="media.attributes.controls">
        <div>{{ parseTime(video ? video.currentTime : 0) }}</div>
        <div class="scrub">
          <input
            type="range"
            @input="handleSeek"
            :value="progress"
            :style="{ '--progress': video?.currentTime / video?.duration }"
            min="0"
            max="1"
            step="0.0001"
          />
        </div>
        <div>{{ parseTime(video ? video.duration : 0) }}</div> -->

      <!-- <div class="volume">
          <input
            @input.prevent="handleVolume"
            :style="{ '--progress': video?.volume }"
            type="range"
            min="0"
            max="1"
            step="0.01"
          />
        </div> -->
      <!-- </div> -->
    </div>

    <video @click="handlePlay" ref="video" v-bind="videoProps"></video>
  </div>
</template>

<style scoped>
.video {
  position: relative;
  display: flex;
}

.ui {
  position: absolute;
  inset: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  pointer-events: none;
}

.ui > * {
  pointer-events: auto;
}

.play-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background 1s ease;
}

.play-btn:hover {
  background: rgba(0, 0, 0, 0);
}
.play-btn:hover .icon {
  opacity: 1;
  transform: scale(1.5);
  transition: transform 0.25s ease-out;
}
.play-btn .icon {
  transition: transform 0.25s ease-out;
  position: absolute;
  width: 5%;
  height: auto;
  max-width: 50px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
