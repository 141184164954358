<script setup>
import { useStore } from "vuex";
const emit = defineEmits(["loaded"]);

const props = defineProps({
  media: Object,
  type: String,
  provider: String,
  url: String,
  title: String,
  description: String,
  code: {
    type: String,
    required: true,
  },
  width: Number,
  height: Number,
  posterImage: Object,
  posters: Array,
  transparent: Boolean,
  responsive: {
    type: Boolean,
    default: true,
  },
  playButton: {
    type: Boolean,
    default: true,
  },
  attributes: Object,
  playsinline: Boolean,
});
const customPoster = inject("customPoster", null);
const poster = computed(() => {
  if (!props.posters || !props.posters.length) return null;
  return customPoster?.src ? customPoster : props.posters[0];
});
const aspectRatio = computed(() => (props.height / props.width) * 100 + "%");
const attributes = computed(() => {
  const { src, type, width, height, ...attributes } = props;
  return attributes;
});

const store = useStore();

const element = ref(null);
const statisticConsent = ref(false);

const handlePlay = () => {
  // store.dispatch("modals/open", {
  //   id: "ModalEmbed",
  //   data: {
  //     data: props.media,
  //     playsinline: true,
  //   },
  // });
  if (process.client) {
    statisticConsent.value = window.cicc["cookie_cat_statistic"];
  }

  if (!statisticConsent.value) {
    store.dispatch("modals/open", {
      id: "ModalCookie",
      data: {
        data: {},
      },
    });
  } else {
    store.dispatch("modals/open", {
      id: "ModalEmbed",
      data: {
        data: props.media,
        playsinline: true,
      },
    });
  }
};

// mounted() {
//   observe(
//     [this.$el],
//     () => {
//       this.show = true
//     },
//     { rootMargin: '50px 0px', threshold: 0.01 }
//   )
// },

onMounted(() => {
  emit("loaded");
});
</script>

<template>
  <div :class="['embed', 'embed--' + type]">
    <span
      class="embed-placeholder"
      v-if="type != 'podcast'"
      :style="{
        paddingBottom: responsive ? aspectRatio : 0,
        backgroundColor: transparent ? 'transparent' : null,
        height: !responsive ? `${height}px` : null,
      }"
    ></span>

    <template v-if="type === 'video' && !playsinline && poster != null">
      <img
        class="embed-poster"
        :src="poster.url || poster.src"
        :srcset="poster.srcset"
        ref="element"
        :class="[isVisible ? 'visible' : 'hidden']"
      />

      <div class="ui" ref="target">
        <button class="play-btn" @click="handlePlay">
          <div class="icon">
            <ui-icons-play />
          </div>
        </button>
      </div>
    </template>
    <template v-else>
      <transition name="embed-fade" @after-enter="fadedIn = true">
        <div class="embed-code" v-html="code"></div>
      </transition>
    </template>
  </div>
</template>

<style lang="postcss">
.embed {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100%;
  background: transparent;
}

.embed > * {
  margin-top: 0;
}

.embed .embed-poster {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  background-color: currentColor;
  transition: opacity 0.5s ease;
  object-fit: cover;
}

.embed .embed-poster.visible {
  opacity: 1;
}

.embed .embed-poster.hidden {
  opacity: 0;
}

.embed .embed-code {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.embed .ui {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
}

.embed .ui > * {
  pointer-events: auto;
}

.embed .play-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background 1s ease;
}

.embed .play-btn:hover {
  background: rgba(0, 0, 0, 0);
}

.embed .play-btn:hover .icon {
  opacity: 1;
  transform: scale(1.5);
  transition: transform 0.25s ease-out;
}

.embed .play-btn .icon {
  transition: transform 0.25s ease-out;
  position: absolute;
  width: 5%;
  height: auto;
  max-width: 50px;
}

.embed .toggle-video-embed {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
  cursor: pointer;
}

.embed .toggle-video-embed .icon-button {
  width: 30px;
  height: 30px;
  transition: transform 0.22s ease;
}

.embed .toggle-video-embed:hover .icon,
.embed .toggle-video-embed:focus .icon {
  transform: scale(1.1);
}

.embed iframe,
.embed img,
.embed .toggle-video-embed,
.embed .video-poster {
  width: 100%;
  height: 100%;
}

.embed--podcast .embed-code {
  width: 100%;
  height: 200px;
  position: relative;
  padding-bottom: 0;
}

.embed-placeholder {
  display: block;
  background-color: currentColor;
  opacity: 0.05;
  transition: opacity 0.32s cubic-bezier(0.67, 0, 0.67, 1) 0.15s;
  pointer-events: none;
}

.embed-placeholder.is-loaded {
  opacity: 0;
}

.embed-placeholder--medium,
.embed-placeholder--large,
.embed-placeholder--wide,
.embed-placeholder--extra-wide,
.embed-placeholder--super-wide {
  display: none;
}

@media screen and (min-width: 768px) {
  .embed-placeholder--medium {
    display: block;
  }
  .embed-placeholder--medium ~ .embed-placeholder {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .embed-placeholder--large {
    display: block;
  }
  .embed-placeholder--large ~ .embed-placeholder {
    display: none;
  }
}

/* Uncomment if needed in future
@media screen and (min-width: var(--wide)) {
  .embed-placeholder--wide {
    display: block;
  }
  .embed-placeholder--wide ~ .embed-placeholder {
    display: none;
  }
}

@media screen and (min-width: var(--extra-wide)) {
  .embed-placeholder--extra-wide {
    display: block;
  }
  .embed-placeholder--extra-wide ~ .embed-placeholder {
    display: none;
  }
}

@media screen and (min-width: var(--super-wide)) {
  .embed-placeholder--super-wide {
    display: block;
  }
  .embed-placeholder--super-wide ~ .embed-placeholder {
    display: none;
  }
}
*/
</style>
