<script setup>
/**
 * note: shouldn't be used directly, instead use the CommonMedia:
 * <CommonMedia :media="myImageOrVideo" />
 */

const props = defineProps({
  media: Object,
  sizes: Array,
  loading: String, // used for overwriting the "loading" attr from cms
  withPlaceholder: { type: Boolean, default: true },
});

const focalPoint = computed(() => {
  if (!props.media.focalPoint) return null;

  const { x, y } = props.media.focalPoint;

  return {
    backgroundPosition: `${x * 100}% ${y * 100}%`,
    objectPosition: `${x * 100}% ${y * 100}%`,
  };
});

const sizesAttribute = computed(() => {
  const [mobileSize, desktopSize] = props.sizes;
  return `(max-width: 900px) ${mobileSize}vw, ${desktopSize}vw`;
});
</script>

<template>
  <img
    :style="{
      ...focalPoint,
      backgroundImage:
        withPlaceholder && props.media.extension !== 'png'
          ? `url(${media.placeholder})`
          : null,
    }"
    v-bind="media.attributes"
    :sizes="sizesAttribute"
    :loading="loading || media.attributes.loading"
    class="image"
  />
</template>

<style>
.image {
  /* background-size: 100% 100%; */
  background-size: cover;
}
</style>
